import { PropsWithChildren } from "react";

import Header, { HeaderCell } from "./components/header";
import Row from "./components/row";
import Cell from "./components/cell";
import Body from "./components/body";

import { ListContext } from "./duck/constants";
import { CellSchema, SortDirection } from "./duck/types";

interface Props<T, K extends string> extends PropsWithChildren {
  data: T[];
  error?: string | null;
  isLoading?: boolean;
  activeSort?: K;
  columnSchema?: CellSchema[];
  sortDirection?: SortDirection;
  onSort?(key: K, direction: SortDirection): void;
}

function List<T, K extends string>({
  children,
  data,
  error = null,
  isLoading = false,
  activeSort,
  sortDirection,
  onSort,
  columnSchema = [],
}: Props<T, K>) {
  return (
    <ListContext.Provider
      value={{
        data,
        error,
        isLoading,
        activeSort,
        sortDirection,
        onSort,
        columnSchema,
      }}
    >
      {children}
    </ListContext.Provider>
  );
}

List.Header = Header;
List.Row = Row;
List.Cell = Cell;
List.HeaderCell = HeaderCell;
List.Body = Body;

export default List;

import { FC, HTMLAttributes } from "react";
import joinClassNames from "classnames";

import { CellProps } from "./duck/types";

import listClasses from "../../styles/classes.module.scss";

interface Props extends CellProps, HTMLAttributes<HTMLDivElement> {}

const Cell: FC<Props> = ({
  children,
  className,
  align = "left",
  ...restProps
}) => (
  <div
    data-align={align}
    className={joinClassNames(listClasses.cell, className)}
    {...restProps}
  >
    {children}
  </div>
);

export default Cell;

import { ReactNode } from "react";
import {
  AutoSizer,
  List,
  ListProps,
  ListRowProps,
  WindowScroller,
} from "react-virtualized";
import joinClassNames from "classnames";

import Button from "basics/button";

import ErrorScreen from "components/error";
import EmptyScreen from "components/empty-screen";
import AnimatedCoin from "components/animated-coin";

import { useListContext } from "../../duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props<T> extends Pick<ListProps, "rowHeight"> {
  className?: string;
  children(props: ListRowProps & { item: T });
  endContent?: ReactNode;
  empty?: {
    title: string;
    subtitle: string;
  };
  onReload?: VoidFunction;
}

function Body<T>({
  className,
  rowHeight = 80,
  children,
  endContent,
  onReload,
  empty,
}: Props<T>) {
  const { data, error, isLoading } = useListContext<T, string>();

  if (error) {
    return (
      <ErrorScreen className={joinClassNames(classes.stateScreen, className)}>
        {onReload && (
          <Button
            themeName="primary"
            onClick={onReload}
            className={classes.errorButton}
          >
            Try again
          </Button>
        )}
      </ErrorScreen>
    );
  }

  if (!data.length && isLoading) {
    return (
      <div className={joinClassNames(classes.stateScreen, className)}>
        <AnimatedCoin className={classes.loader} />
      </div>
    );
  }

  if (!data.length) {
    return (
      <EmptyScreen
        className={joinClassNames(classes.stateScreen, className)}
        title={empty?.title}
        subtitle={empty?.subtitle}
      />
    );
  }

  return (
    <div className={joinClassNames(classes.wrapper, className)}>
      <WindowScroller>
        {({ height, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <div style={{ width }}>
                <List
                  tabIndex={-1}
                  width={width}
                  autoHeight
                  height={height}
                  scrollTop={scrollTop}
                  rowCount={data.length}
                  rowHeight={rowHeight}
                  rowRenderer={props =>
                    children({ ...props, item: data[props.index] })
                  }
                />
                {endContent}
              </div>
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    </div>
  );
}

export default Body;

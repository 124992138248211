import { HTMLAttributes, ReactNode } from "react";
import joinClassNames from "classnames";

import ArrowDown16Icon from "icons/arrow-down16.svg?react";

import { CellProps } from "../../../cell";

import { useListContext } from "../../../../duck/hooks";

import classes from "./styles/classes.module.scss";
import listClasses from "../../../../styles/classes.module.scss";

interface Cell extends CellProps, HTMLAttributes<HTMLDivElement> {}
interface SortableCell extends CellProps, HTMLAttributes<HTMLButtonElement> {
  sortKey: string;
  reverseDefaultSort?: boolean;
}

function HeaderCell(props: Cell): ReactNode;
function HeaderCell(props: SortableCell): ReactNode;

function HeaderCell({
  children,
  className,
  sortKey,
  align,
  reverseDefaultSort = false,
  ...restProps
}) {
  const { sortDirection, activeSort, onSort, data, error, isLoading } =
    useListContext();
  const commonProps = {
    className: joinClassNames(listClasses.cell, classes.wrapper, className),
    "data-align": align || "left",
  };

  if (sortKey && onSort) {
    const isSortDesc = sortDirection === "desc";
    const reverseDirection = isSortDesc ? "asc" : "desc";
    const defaultSort = reverseDefaultSort ? "asc" : "desc";
    const isActive = activeSort === sortKey;

    return (
      <button
        data-column={sortKey}
        aria-selected={isActive}
        disabled={Boolean(error || (!data.length && !isLoading))}
        onClick={() => {
          onSort(sortKey, isActive ? reverseDirection : defaultSort);
        }}
        {...restProps}
        {...commonProps}
      >
        {children}{" "}
        <ArrowDown16Icon
          className={classes.arrowDown16Icon}
          data-rotate={isActive ? isSortDesc : defaultSort === "desc"}
        />
      </button>
    );
  }

  return (
    <p {...restProps} {...commonProps}>
      {children}
    </p>
  );
}

export default HeaderCell;

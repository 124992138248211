import joinClassNames from "classnames";
import { ComponentProps, ElementType, ReactElement } from "react";

import { useListContext } from "../../duck/hooks";
import { filterChildren } from "../../duck/selectors";

import classes from "./styles/classes.module.scss";

interface Props<T extends ElementType> {
  as?: T;
  children: ReactElement[];
}

function Row<T extends ElementType = "button">({
  children,
  className,
  as: Comp = "button",
  ...restProps
}: Props<T> & ComponentProps<T>) {
  const { columnSchema } = useListContext();

  return (
    <Comp className={joinClassNames(classes.wrapper, className)} {...restProps}>
      {filterChildren(children, columnSchema)}
    </Comp>
  );
}

export default Row;

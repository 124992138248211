import { FC, ReactElement } from "react";
import joinClassNames from "classnames";

import { useListContext } from "../../duck/hooks";
import { filterChildren } from "../../duck/selectors";

import classes from "./styles/classes.module.scss";

interface Props {
  className?: string;
  children: ReactElement[];
}

const Header: FC<Props> = ({ children, className }) => {
  const { columnSchema } = useListContext();

  return (
    <div className={joinClassNames(classes.wrapper, className)}>
      {filterChildren(children, columnSchema)}
    </div>
  );
};

export default Header;

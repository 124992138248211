import { FC, useEffect, useRef } from "react";
import joinClassNames from "classnames";

import Button from "basics/button";

import AnimatedCoin from "components/animated-coin";

import classes from "./styles/classes.module.scss";

interface Props {
  onLoadMore: VoidFunction;
  isLoading: boolean;
  isNeedExecute?: boolean;
  isError?: boolean;
  options?: IntersectionObserverInit;
}

const InfiniteScroll: FC<Props> = ({
  onLoadMore,
  options,
  isLoading,
  isNeedExecute = true,
  isError = false,
}) => {
  const loaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onIntersect: IntersectionObserverCallback = ([entry]) => {
      if (!isNeedExecute || isLoading || isError) {
        return;
      }

      if (entry.isIntersecting) {
        onLoadMore();
      }
    };

    const observer = new IntersectionObserver(onIntersect, options);

    observer.observe(loaderRef.current as HTMLDivElement);

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [isNeedExecute, isLoading, isError]);

  return (
    <div
      className={joinClassNames(classes.wrapper, {
        [classes.topIndent]: isError || (isNeedExecute && isLoading),
      })}
      ref={loaderRef}
    >
      {isError ? (
        <Button themeName="primary" onClick={onLoadMore}>
          Try load more
        </Button>
      ) : (
        isNeedExecute && isLoading && <AnimatedCoin />
      )}
    </div>
  );
};

export default InfiniteScroll;

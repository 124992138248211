import { ReactElement, cloneElement } from "react";
import { merge } from "lodash";

import { CellSchema } from "./types";

export const filterChildren = (
  children: ReactElement[],
  columnSchema: CellSchema[],
): ReactElement[] =>
  children.reduce((acc, child, index) => {
    const schema = columnSchema[index];
    if (schema === null) {
      return acc;
    }

    return acc.concat(
      cloneElement(child, {
        // eslint-disable-next-line react/no-array-index-key
        key: child.key || index,
        style: merge(child.props.style, schema || { flex: 1 }),
      }),
    );
  }, []);
